import React, { useMemo } from "react";
import TextInfo from "@/bit/components/components.text";
import Button, { BUTTON_TYPE } from "@/bit/components/components.button";

const TVFilters = (props) => {
  const taggingInfoButtonConfigura = {
    componentName: "tv-filters",
    componentVariation: "all",
    componentContent:
      props["ButtonConfigura-1"] && props["ButtonConfigura-1"].label
        ? props["ButtonConfigura-1"].label
        : "",
    componentProduct: props.alias || "",
    eventType: "click",
  };

  const cardValues = useMemo(() => {
    const cardKeys = Object.keys(props).filter((key) =>
      /^Button-\d+$/.test(key)
    );

    return cardKeys.map((key) => props[key]);
  }, [props]);

  return (
    <section className="tv-filters">
      {props["Text-1"] && (
        <div className="tv-filters--title">
          <TextInfo
            {...props["Text-1"]}
            kind="h"
            level="2"
            cyData="TVFilters Title"
          />
          {props["ButtonConfigura-1"] && props?.showButton && (
            <Button
              {...props["ButtonConfigura-1"]}
              taggingInfo={taggingInfoButtonConfigura}
              cyData="TVFilters ButtonConfigura-1"
              type="primary"
              className={`tv-filters--button-configura${
                props.alias === "configura-tv-r" ? " button-tv-hide" : ""
              }`}
            />
          )}
        </div>
      )}
      {cardValues && Array.isArray(cardValues) && cardValues.length > 0 && (
        <div className="tv-filters--row">
          {cardValues.map((charac, index) => (
            <div
              className="tv-filters--row_button"
              key={`tv-filters--${index}`}
            >
              <Button
                type={
                  charac.selected
                    ? BUTTON_TYPE.PRIMARY_REVERSE
                    : BUTTON_TYPE.COLLAPSED
                }
                {...charac}
                onClick={(e) => props.applyFilter(charac, cardValues)}
                taggingInfo={{
                  componentName: "tv-filters",
                  componentVariation: "all",
                  componentContent: charac.label,
                  componentProduct: charac.label,
                  eventType: "click",
                }}
                cyData={`TVFilters-${index} Button`}
              />
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

TVFilters.propTypes = {};

TVFilters.defaultProps = {};

export default TVFilters;
