import React from "react";
import Image from "@/bit/components/components.c-image";
import TextInfo from "@/bit/components/components.text";
import Button from "@/bit/components/components.button";

const COMPONENT_NAME = "IMAGE_STEPS_CARD";

const DescriptionCard = (props) => {
  const isWebView = process.env.NEXT_PUBLIC_WEB_VIEW === "true";
  return (
    <>
      <div className="image-steps-card-container" data-cy="Image Steps Card">
        <div className="content-container">
          <div className="card-header" data-cy="Image Steps Card Header">
            {props["Text-1"] && props["Text-1"].text && (
              <TextInfo {...props["Text-1"]} cyData="Image Steps Card Title" />
            )}

            {props["Text-2"] && props["Text-2"].text && (
              <TextInfo
                {...props["Text-2"]}
                cyData="Image Steps Card SubTitle"
              />
            )}
          </div>

          <div className="card-body">
            {props["Text-3"] && props["Text-3"].text && (
              <TextInfo
                {...props["Text-3"]}
                cyData="Image Steps Card Paragraph"
              />
            )}

            {!isWebView && props["Button-1"] && (
              <Button
                {...props["Button-1"]}
                cyData="Image Steps Card Button"
                taggingInfo={{ ...props.taggingInfo, eventType: "click" }}
              />
            )}
          </div>
        </div>
        <div className="image-container">
          {props["Image-1"] && props["Image-1"].src && (
            <Image {...props["Image-1"]} cyData="Image Steps Card Image" />
          )}
        </div>
      </div>
      <style jsx>{`
        .image-steps-card-container {
          border: 1px solid rgb(221, 221, 221);
          min-height: 100px;
          border-radius: 1rem;
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 1fr 1fr;
          gap: 1rem;
          overflow: hidden;

          & :global(*) {
            line-height: 1.5rem;
          }

          .content-container {
            padding: 1rem;
            flex: 1;

            .card-header {
              margin-bottom: 1rem;

              & > :global(*) {
                margin-bottom: 4px;
              }
            }

            .card-body {
              & > :global(*) {
                line-height: 170%;
              }

              :global(.next-link) {
                justify-content: flex-start;
                padding: 0;
                padding-top: 1rem;
              }
            }
          }

          .image-container {
            grid-row: 1;
            position: relative;
            overflow: hidden;

            :global(.image) {
              width: 100%;
              height: 100%;
              object-fit: cover;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }

        @media screen and (min-width: 768px) {
          .image-steps-card-container {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr;

            .content-container {
            }

            .image-container {
              grid-row: 0;
              grid-column: 2;
            }
          }
        }
      `}</style>
    </>
  );
};

export default DescriptionCard;
