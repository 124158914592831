import React from "react";
import Advantage from "./components/advantage";
import Text from "@/bit/components/components.text";

const AdvantagesSection = (props) => {
  let listAdvantages = null;
  if (Object.keys(props).includes("Advantage-1")) {
    listAdvantages = Object.keys(props)
      .filter((key) => key.startsWith("Advantage-"))
      .map((key) => props[key]);
  } else {
    listAdvantages = Object.keys(props)
      .filter((key) => key.startsWith("Charac-"))
      .map((key) => props[key]);
  }

  return (
    <>
      <section
        className={`advantages-section ${
          Object.keys(props).includes("Advantage-1") && "noShowMobile"
        }`}
      >
        <div className="container">
          {props["Text-1"] && (
            <Text
              cyData="Advantages Text-1"
              className="advantages-section-title"
              {...props["Text-1"]}
            />
          )}
          <ul
            className="advantages-section-list"
            data-cy="Advantages list items"
          >
            {listAdvantages &&
              listAdvantages.map((advantage, index) => (
                <li
                  key={`item-${index}`}
                  className="advantages-section-item"
                  data-cy="Advantages item"
                >
                  <Advantage {...advantage} />
                </li>
              ))}
          </ul>
        </div>
      </section>
      <style jsx>
        {`
          @import "../../styles/colors.scss";
          .advantages-section {
            margin: 2rem 0;
            background-color: $color-gp--transparent;
            :global(.advantages-section-title) {
              padding-bottom: 2rem;
            }
            .advantages-section-list {
              display: grid;
              grid-template-columns: repeat(${props?.columns || "3"}, 1fr);
              gap: 5rem;
              justify-content: center;
            }

            @media screen and (max-width: 992px) {
              .advantages-section-list {
                gap: 1rem;
              }
              &.noShowMobile {
                display: none;
              }
            }
            @media screen and (max-width: 768px) {
              .advantages-section-list {
                display: flex;

                flex-wrap: wrap;
                justify-content: space-around;
                .advantages-section-item {
                  width: 40%;
                }
              }
            }
          }
        `}
      </style>
    </>
  );
};

export default AdvantagesSection;
