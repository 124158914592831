import styled from "styled-components";

export default styled.article`
  .image-cards {
    .carousel {
      .carousel-window {
        gap: 1.5rem;
      }
    }
  }
  .image-card {
    background-color: blue;
    width: 100%;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    min-height: 100%;
    width: 302px;
    min-width: 302px;

    .card-content {
      position: absolute;
      padding: 0.5rem;
      z-index: 1;
      display: flex;
      height: 100%;
      align-items: flex-end;
      pointer-events: none;

      & :global(*) {
        line-height: 120%;
      }
    }
  }

  .shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(49, 50, 53);
    background: linear-gradient(
      180deg,
      rgba(49, 50, 53, 0) 0%,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.10407913165266103) 47%,
      rgba(0, 0, 0, 0.3337710084033614) 66%,
      rgba(0, 0, 0, 1) 100%
    );
  }

  .small {
    grid-row: span 1;
  }

  .big {
    grid-row: span 2;
  }

  .inline-layout {
    padding: 2rem 0;
    .title-container {
      margin-bottom: 1rem;
    }
    .image-cards {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  @media screen and (min-width: 760px) {
    .image-card {
      .card-content {
        padding: 1rem;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .inline-layout {
      .image-cards {
        &--reverse {
          order: -1;
        }
      }
    }
  }
`;
