import React from "react";
import Image from "@/bit/components/components.c-image";
import Link from "@/bit/components/components.custom-link";
import Text from "@/bit/components/components.text";

const ImageCardBig = (props) => {
  const { categories, hideShadow } = props;
  const cyData = `${categories && Array.isArray(categories) && categories.map((cat) => `${cat} `)}`;

  let title = "";
  let desc = "";
  if (props["Text-1"] && props["Text-1"].text) {
    const normalizedText = props["Text-1"].text.replace("<BR>", "<br>");
    const textSplited = normalizedText.split("<br>");
    title = textSplited[0];
    desc = textSplited[1];
  }
  return (
    <>
      <div className="image-cluster--item" data-cy="ImageCluster Card">
        <div className="image-cluster--item--image">
          <Image fill objectFit="cover" {...props["Image-1"]} cyData={cyData} />
          {!hideShadow && <div className="shadow" />}
        </div>
        {title && (
          <div className="image-cluster--item--text">
            <div className="image-cluster--item--container-text">
              {title && (
                <Text
                  {...props["Text-1"]}
                  text={title}
                  color="blue_dark"
                  kind="h"
                  fontType="font-bold"
                  level="3"
                  className="image-cluster--item--title"
                  cyData="ImageCluster Text-1"
                />
              )}
              {desc && (
                <Text
                  {...props["Text-1"]}
                  color="grey5"
                  text={desc}
                  className="recom-characterisctics--item--description"
                  cyData="ImageCluster Text-2"
                />
              )}
              {props["Link-1"] && <Link {...props["Link-1"]} />}
            </div>
          </div>
        )}
      </div>
      <style jsx>{`
        .image-cluster {
          display: flex;
          flex-direction: column;
          padding-bottom: 0.5rem;
          .shadow {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgb(49, 50, 53);
            background: linear-gradient(
              180deg,
              rgba(49, 50, 53, 0) 0%,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.10407913165266103) 47%,
              rgba(0, 0, 0, 0.3337710084033614) 66%,
              rgba(0, 0, 0, 1) 100%
            );
          }
          &--item {
            display: flex;
            flex-direction: column;
            height: 100%;
            border-radius: 16px;
            overflow: hidden;
            border: 1px solid #dddddd;
            width: min(24.9vw,352px);
            min-width: 302px;
            background: white;

            &--container-text {
              display: flex;
              flex-flow: column;
              gap: 4px;
              :global(a) {
                cursor: default;
              }
            }

            &--text {
              padding: 1rem;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;
              gap: 4px;

              :global(.image-cluster--item--title) {
                line-height: 1.5;
              }
              :global(.image-cluster--item--description) {
                line-height: 1.5;
              }
            }
            &--image {
              position: relative;
              min-height: 13rem;
              width: 100%;
              padding-top: 75%
            }
          }
          @media screen and (max-width: 1024px) {
            .image-cluster {
              &--item {
                width: 20rem;
              }
            }
          }
        }
      `}</style>
    </>
  );
};

export default ImageCardBig;
