/* eslint-disable react/prefer-stateless-function */
import React, { useState } from "react";
import TextInfo, { TEXT_COLOR } from "@/bit/components/components.text";
import CustomLink from "@/bit/components/components.custom-link";
import IconGlyph from "@/bit/components/components.icon-glyph";
import Carousel from "@/bit/components/components.carousel";
import Card from "../card";

const ChannelLink = ({ channelCards }) => {
  if (!channelCards) {
    return null;
  }
  const [open, setopen] = useState(false);
  const toggleQuestion = () => {
    setopen(!open);
  };

  let products = {};
  if (channelCards) {
    products = channelCards;
  }
  const cardKeys = Object.keys(products).filter((key) =>
    /^Product-\d+$/.test(key)
  );
  const productValues = cardKeys.map((key) => products[key]);

  return (
    <>
      <div className="tv-channel-link">
        <CustomLink
          onClick={() => toggleQuestion()}
          title="Ver canales a la carta"
          taggingInfo={{
            componentVariation: "",
            componentContent: "Ver canales a la carta",
            eventType: "click",
          }}
          cyData="ChannelLink CustomLink"
          className="channel-link"
        >
          <TextInfo
            text="Ver canales a la carta"
            fontType="font-regular"
            size="caption01"
            color="blue_a"
            display="block"
            cyData="ChannelLink Text"
          />
          <IconGlyph
            className={`${open ? "open cheuron" : "cheuron"}`}
            id="cheuron-up-m"
            height={12}
            color={TEXT_COLOR.BLUE}
          />
        </CustomLink>
        {open && (
          <div
            className="tv-card-container-grid"
            data-cy={`TVCard grid channel`}
          >
            {productValues &&
              Array.isArray(productValues) &&
              productValues.length > 0 &&
              productValues.map((card, index) => (
                <Card {...card} key={`tv-card-${index}`} index={index} />
              ))}
          </div>
        )}
      </div>

      <style jsx>{`
        :global(.tv-card-container .tv-channel-link) {
          :global(.channel-link) {
            display: flex;
            align-items: center;
            padding-top: 1.5rem;
            gap: 0.5rem;
          }
          :global(.tv-card-container-grid) {
            margin-top: 1.5rem;
          }

          :global(.cheuron) {
            transform: rotate(180deg);
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
          }

          :global(.open) {
            transform: rotate(0deg);
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
          }
        }
        @media screen and (max-width: 768px) {
        }
        @media screen and (max-width: 768px) {
        }
      `}</style>
    </>
  );
};

export default ChannelLink;
