import React from "react";
import TextInfo from "@/bit/components/components.text";
import Faqs from "@/bit/components/components.faqs";

const FaqsCompo = (props) => {
  const faqKeys = Object.keys(props).filter((key) => /^Faq-\d+$/.test(key));
  const faqValues = faqKeys.map((key) => props[key]);

  return (
    <section className="FaqsCompo container" data-cy="Section Faqs">
      {props["Text-1"] && (
        <TextInfo
          className="faqs--title"        
          {...props["Text-1"]}
          kind="h" level="2"
          cyData="Faqs Title"
        />
      )}
      <Faqs listadoFAQS={faqValues} />
      <style jsx>
        {`
          .FaqsCompo {
            margin-top: 1.5rem;
            padding-bottom: 2rem;
            :global(.faqs--title) {
              margin-bottom: 1rem;
            }
            :global(.faq-item__content--opened > span,p) {
              line-height: 1.5;
            }
          }
        `}
      </style>
    </section>
  );
};

export default FaqsCompo;
