import React from "react";
import TextInfo from "@/bit/components/components.text";
import ImageStepsCard from "./card";

const componentName = "Image Steps Container";

const ImageStepsComponent = (props) => {
  const taggingInfo = {
    componentName: componentName,
    componentVariation: "all",
    componentContent: props["Text-1"] ? props["Text-1"] : "",
    componentProduct: "",
  };
  return (
    <>
      <div
        className="image-spets-card container"
        data-cy="Image Steps Component"
      >
        <div className="content-container">
          {props["Text-1"] && props["Text-1"].text && (
            <div className="title-cont" data-cy="Image Steps Title">
              <TextInfo {...props["Text-1"]} />
            </div>
          )}

          {props["Card-1"] && (
            <ImageStepsCard {...props["Card-1"]} taggignInfo={taggingInfo} />
          )}
        </div>
      </div>
      <style jsx>{`
        .image-spets-card {
          & :global(*) {
            line-height: 120%;
          }

          .content-container {
            .title-cont {
              margin: 1rem 0;
            }
          }
        }
      `}</style>
    </>
  );
};

export default ImageStepsComponent;
