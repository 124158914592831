import React from "react";
import Text from "@/bit/components/components.text";
import Carousel from "@/bit/components/components.carousel";
import ImageCard from "./image-card";
import StyledImageClusterInfo from "./styles";

const ImageClusterInfo = (props) => {
  const { category, allEvents, cards, hideShadow, ...restProps } = props;

  let modules = Object.keys(cards || restProps)
    .filter((key) => key.includes("Module"))
    .map((key) => (cards || restProps)[key]);

  if (modules.length === 0) {
    return null;
  }

  const moduleProps = category === "todos" ? { ...allEvents, categories: ["tv-todos"] } : { ...modules[0] };

  const imageCards = Object.keys(moduleProps)
    .filter((key) => key.includes("Image-Card"))
    .map((key) => moduleProps[key]);

  return (
    <StyledImageClusterInfo
      style={{ backgroundColor: moduleProps?.backgroundColor || "white" }}
      id="Advantages"
    >
      <div className="container">
        <div className="inline-layout">
          <div className="title-container" data-cy="Title container Cards">
            {props["Text-1"] && <Text {...props["Text-1"]} cyData="Title Section Images Cluster" />}
          </div>
          <div className="image-cards" data-cy="Image Cards">
            {imageCards && Array.isArray(imageCards) && imageCards.length > 0 && (
              <Carousel maxPointNum={0}>
                {imageCards.map(
                  (cardData, i) => cardData && <ImageCard {...cardData} hideShadow={hideShadow} categories={moduleProps.categories} isSmall key={`card-${i}`} />
                )}
              </Carousel>
            )}
          </div>
        </div>
      </div>
    </StyledImageClusterInfo>
  );
};

export default ImageClusterInfo;
