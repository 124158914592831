import React from 'react';
import Text from '@/bit/components/components.text';
import Glyph from "@/bit/components/components.icon-glyph";


const Advantage = (props) => {
  return (
    <>
      <div className="advantages">
        {props["Glyph-1"] && (
          <Glyph 
            id={props['Glyph-1'].id.toLowerCase()}
            kind={props['Glyph-1'].kind}
            width={props['Glyph-1'].width}
            color={props['Glyph-1'].color}
          />
        )}
        {props['Text-1'] && (
          <Text
            {...props['Text-1']}
            align="center"
            cyData="Advantage title Text-1"
          />
        )}
        {props['Text-2'] && (
          <Text
            {...props['Text-2']}
            align="center"
            cyData="Advantage description Text-2"
          />
        )}
        {props['Text-3'] && (
          <Text
            {...props['Text-3']}
            align="center"
            cyData="Advantage description Text-3"
          />
        )}
      </div>
      <style jsx>
        {`
          .advantages {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
          }
        `}
      </style>
    </>
  );
};

export default Advantage;
