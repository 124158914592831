import React from "react";
import PropTypes from "prop-types";
import TextInfo from "@/bit/components/components.text";
import Carousel from "@/bit/components/components.carousel";
import Image from "@/bit/components/components.c-image";

const ChannelList = ({ title, channelList, separator }) => {
  if (!channelList) {
    return null;
  }
  const channels = Object.values(channelList);

  return (
    <section className="channel-list container" data-cy="Channel List section">
      {separator && <hr className="separator"></hr>}
      <div className="channel-list__container">
        {title && (
          <TextInfo
            text="Canales incluidos:"
            {...title}
            className="channel-list__title"
            cyData="ChannelList Title"
          />
        )}
        <div className="channel-list__content" data-cy="ChannelList Carousel">
          <Carousel
            maxPointNum={3}
            blurColor={[255, 255, 255]}
            arrowWidth={35}
            slideSeparation={24}
          >
            {channels &&
              Array.isArray(channels) &&
              channels.map((channel, i) => (
                <div className="channel-list__img" key={`${i}`}>
                  <Image
                    src={channel.image.src}
                    alt="channel image"
                    width={channel.image.width}
                    height={channel.image.height}
                    objectFit="contain"
                    cyData="ChannelList Image"
                  />
                </div>
              ))}
          </Carousel>
        </div>
      </div>
      <style jsx>
        {`
          .channel-list {
            padding-top: ${separator ? "0px" : "24px"};
            padding-bottom: ${separator ? "0px" : "24px"};

            hr.separator {
              color: #dddddd;
              margin-top: 2rem;
              margin-bottom: 1rem;
            }

            @media (max-width: 768px) {
              width: 100%;

              :global(.carousel-window) {
                overflow-x: scroll;
                gap: 16px;
              }
            }

            &__title {
              margin-bottom: 2rem;
            }
            &__content {
              margin: 1rem 0;
              .arrow-container {
                top: 38%;
              }

              .channel-list__img {
                width: 124px;
                height: 124px;
                padding: 0.8rem;
                border: 1px solid #ddd;
                box-shadow: 0 0 5px 0 #ededed;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
              }
            }
          }
        `}
      </style>
    </section>
  );
};

ChannelList.propTypes = {
  title: PropTypes.string,
  channelList: PropTypes.object,
};

ChannelList.defaultProps = {
  title: "",
  channelList: {},
  separator: false,
};

export default ChannelList;
