/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import Carousel from "@/bit/components/components.carousel";
import TextInfo from "@/bit/components/components.text";
import Button from "@/bit/components/components.button";
import Card from "./card";
import ChannelLink from "./channel-link";

class TVCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { props } = this;
    const { category, channelCards } = props;
    let products = {};
    if (props && props?.cards) {
      products = props?.cards;
    } else if (props && props?.["Pack-Cards"]) {
      products = props?.["Pack-Cards"];
    }
    const cardKeys = Object.keys(products).filter((key) =>
      /^Product-\d+$/.test(key)
    );
    const productValues = cardKeys.map((key) => products[key]);
    const taggingInfoButtonConfigura = {
      componentName: "tv-card",
      componentVariation: "all",
      componentContent:
        props["ButtonDesk-1"] && props["ButtonDesk-1"].label
          ? props["ButtonDesk-1"].label
          : "",
      componentProduct: props.alias || "",
      eventType: "click",
    };

    return (
      <>
        <div className="tv-card-container container">
          <div className="tv-card-section-title">
            {props["Text-5"] && (
              <TextInfo {...props["Text-5"]} cyData="TVCard Text-5" />
            )}
            {props["ButtonDesk-1"] && (
              <Button
                className="button-configura-desk"
                {...props["ButtonDesk-1"]}
                taggingInfo={taggingInfoButtonConfigura}
                cyData="TVCard ButtonDesk-1"
                type="primary"
              />
            )}
          </div>
          {productValues ? (
            <div
              className="tv-card-container-grid"
              data-cy={`TVCard grid ${props.module}`}
            >
              {productValues &&
                Array.isArray(productValues) &&
                productValues.length > 0 &&
                productValues.map((card, index) => (
                  <Card {...card} key={`tv-card-${index}`} index={index} />
                ))}
            </div>
          ) : (
            <div
              className="tv-card-container-carousel"
              data-cy={`TVCard carousel ${props.module}`}
            >
              <Carousel
                className="tv-card-content"
                maxPointNum={0}
                breakpoint={768}
              >
                {productValues &&
                  Array.isArray(productValues) &&
                  productValues.length > 0 &&
                  productValues.map((card, index) => (
                    <Card {...card} key={`tv-card-${index}`} index={index} />
                  ))}
              </Carousel>
            </div>
          )}
          {props["ButtonMobile-1"] && (
            <div className="container-button-configura-mobile">
              <Button
                className="button-configura-mobile"
                {...props["ButtonMobile-1"]}
                taggingInfo={taggingInfoButtonConfigura}
                cyData="TVCard ButtonMobile-1"
              />
            </div>
          )}
          {/* CANALES A LA CARTA EN FILTRO TODOS */}
          {category && category === "todos" && <ChannelLink channelCards={channelCards} />}
        </div>

        <style jsx>{`
          @media screen and (max-width: 768px) {
            :global(.button-tv-hide) {
              display: none;
            }
          }
          @media screen and (max-width: 768px) {
            .tv-card-section-title {
              .tv-card-container-grid {
                display: none;
              }
            }
          }
        `}</style>
      </>
    );
  }
}

export default TVCard;
