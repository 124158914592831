import React, { Component } from "react";
import { withRouter } from "next/router";
import { connect } from "react-redux";
import dynamic from "next/dynamic";
import ProductBanner from "@/component/product-banner";
import FaqsCompo from "@/component/faqs";
import RecomCharacteristics from "@/component/recom-characteristics";
import ImageStepsCard from "@/component/image-steps-card";
import TVCard from "@/component/tv-card";
import TVFilters from "@/component/tv-filters";
import AdvantagesSection from "@/component/advantages-section";
import ChannelList from "@/component/channel-list";
import ImageClusterWithoutText from "@/component/image-cluster-without-text";

const Devices = dynamic(() => import("@/component/devices-offers-bf"));
const ImageClusertInfoModule = dynamic(
  () => import("@/bit/components/components.image-cluster-info-module")
);

/**
 * @name Layout
 * @description Connectivity page layout
 * @param {any} props
 * @returns {React.FC}
 */
class Layout extends Component {
  constructor(props) {
    super(props);

    const queryParams = new URLSearchParams(props.router.asPath.split("?")[1]);
    const filters = queryParams.get("filters");

    const filteredObjectProducts = this.getMatchingKeysObject(
      props.MODULO_FILTER_CATEGORY,
      "Product-"
    );

    const filteredObjectCards = this.getMatchingKeysObject(
      props.MODULO_CARDS_CONTENIDOS_FILTER,
      "Card-"
    );

    const filteredObjectModules = this.getMatchingKeysObject(
      props.MODULO_IMAGENES_CATEGORIA,
      "Module-"
    );

    const filteredAllEvents = this.getMatchingKeysObject(
      props.MODULO_IMAGENES_CATEGORIA,
      "All-Events"
    );

    const filteredObjectButtons = this.getMatchingKeysObject(
      props.MODULO_SELECTORES_TV,
      "Button-"
    );

    const filteredSimpleCard = this.getMatchingKeysObject(
      props.MODULO_SIMPLE_CARD,
      "Product-"
    );

    const simpleCard = this.filteredObjectByCategory(filteredSimpleCard);

    const selectedButton =
      filteredObjectButtons &&
      Object.values(filteredObjectButtons).find(
        (button) => button.category === filters
      );

    if (selectedButton) {
      Object.values(filteredObjectButtons).forEach((button) => {
        button.selected = false;
      });

      selectedButton.selected = true;
    } else {
      console.log("Botón con la categoría no encontrado");
    }

    this.state = {
      devicesData: null,
      filtersButtons: filteredObjectButtons,
      selectfilter: selectedButton,
      simpleCard: simpleCard,
      originalSimpleCards: filteredSimpleCard,
      products: null,
      featuredContent: null,
      events: null,
      originalObjectProducts: filteredObjectProducts,
      originalObjectFeaturedContent: filteredObjectCards,
      originalObjectEvents: filteredObjectModules,
      currentCategory: filters || "todos",
      rehydrate: false,
      originalAllEvents: filteredAllEvents,
    };
  }
  // Ficha BF componente custo
  async fetchData() {
    const { context } = this.props;
    if (
      this.props.COMPONENT_TYPE &&
      this.props.COMPONENT_TYPE.creativityId ===
        "CMS-GP-OFERTAS-DISPOSITIVOS-CONVERGENTE"
    ) {
      try {
        const module = await import("@gcloud-npm/adabyron.custo.get-by-name");
        const getByName = module.default;
        const custoInfo = await getByName({
          name: "CMS-GP-OFERTAS-DISPOSITIVOS-CONVERGENTE",
          context,
          build: false,
          kind: "activity",
        });
        this.setState({
          devicesData: custoInfo,
        });
      } catch (error) {
        // Handle any errors that might occur during the fetching process
        console.error(error);
      }
    }
  }

  filteredObjectByCategory = (object, category, excludeCategory) => {
    if (!object) return null;

    // Filtrar los objetos que contienen la categoría deseada
    const objetoFiltrado = Object.keys(object).reduce((resultado, clave) => {
      const modulo = object[clave];

      // Verificar si el objeto tiene la categoría deseada
      if (modulo.categories) {
        if (modulo.categories.includes(category)) {
          resultado[clave] = modulo;
        }
      } else if (!modulo.categories) {
        // Si no hay categoria, agregar el objeto original
        resultado[clave] = modulo;
      }
      return resultado;
    }, {});

    return objetoFiltrado;
  };

  applyFilterByCategory = (selectfilter, arrayButtons) => {
    const {
      originalObjectProducts,
      originalObjectFeaturedContent,
      originalObjectEvents,
      originalSimpleCards,
      currentCategory,
    } = this.state;
    // Verifica si hay un hashtag en la URL y lo elimina
    if (window.location.hash && window.location.hash !== "#") {
      history.replaceState(
        null,
        document.title,
        window.location.pathname + window.location.search
      );
    }

    const { category } = selectfilter;
    const currentSelected = arrayButtons.filter(
      (button) => button.selected === true
    );

    const simpleCard = this.filteredObjectByCategory(
      originalSimpleCards,
      category
    );

    if (category === "todos") {
      this.setState({
        products: originalObjectProducts,
        featuredContent: originalObjectFeaturedContent,
        events: originalObjectEvents,
        simpleCard,
      });
    } else {
      const productsByCategory = this.filteredObjectByCategory(
        originalObjectProducts,
        category
      );
      const featuredContentByCategory = this.filteredObjectByCategory(
        originalObjectFeaturedContent,
        category
      );

      const EventesByCategory = this.filteredObjectByCategory(
        originalObjectEvents,
        category
      );

      this.setState({
        products: productsByCategory,
        featuredContent: featuredContentByCategory,
        events: EventesByCategory,
        simpleCard,
      });
    }
    if (currentCategory !== category) {
      selectfilter.selected = true;
      currentSelected[0].selected = false;
      this.setState({
        currentCategory: category,
        simpleCard,
      });
    }
  };

  applyFiltersFromURL = () => {
    const { selectfilter, filtersButtons } = this.state;
    const { router } = this.props;

    const queryParams = new URLSearchParams(router.asPath.split("?")[1]);
    const filters = queryParams.get("filters");

    if (filters && selectfilter && filtersButtons) {
      const buttonKeys =
        filtersButtons &&
        Object.keys(filtersButtons).filter((key) => /^Button-\d+$/.test(key));
      const buttonValues = buttonKeys?.map((key) => filtersButtons[key]);
      this.applyFilterByCategory(selectfilter, buttonValues);
    }
  };

  getMatchingKeysObject = (object, startsKey) => {
    let finalObject = {};
    if (!object || typeof object !== "object") return null;

    Object.entries(object).forEach(([key, value]) => {
      if (typeof value === "object") {
        finalObject = {
          ...finalObject,
          ...this.getMatchingKeysObject(value, startsKey),
        };
      }

      if (key.startsWith(startsKey)) {
        finalObject[key] = value;
      }
    });

    return finalObject;
  };

  async handleRehydrate() {
    const { props } = this;
    const filteredObjectProducts =
      props.MODULO_FILTER_CATEGORY &&
      Object.fromEntries(
        Object.entries(props.MODULO_FILTER_CATEGORY["Pack-Cards"]).filter(
          ([key, value]) => key.startsWith("Product-")
        )
      );
    const filteredObjectCards =
      props.MODULO_CARDS_CONTENIDOS_FILTER &&
      Object.fromEntries(
        Object.entries(props.MODULO_CARDS_CONTENIDOS_FILTER).filter(
          ([key, value]) => key.startsWith("Card-")
        )
      );
    const filteredObjectModules =
      props.MODULO_IMAGENES_CATEGORIA &&
      Object.fromEntries(
        Object.entries(props.MODULO_IMAGENES_CATEGORIA).filter(([key, value]) =>
          key.startsWith("Module-")
        )
      );
    const queryParams = new URLSearchParams(props.router.asPath.split("?")[1]);
    const filters = queryParams.get("filters");
    const filteredObjectButtons =
      props.MODULO_SELECTORES_TV &&
      Object.fromEntries(
        Object.entries(props.MODULO_SELECTORES_TV).filter(([key, value]) =>
          key.startsWith("Button-")
        )
      );
    const selectedButton =
      filteredObjectButtons &&
      Object.values(filteredObjectButtons).find(
        (button) => button.category === filters
      );
    if (selectedButton) {
      // Desactiva selected en todos los botones
      Object.values(filteredObjectButtons).forEach((button) => {
        button.selected = false;
      });
      // Activa selected en el botón seleccionado
      selectedButton.selected = true;
    } else {
      console.log("Botón con la categoría no encontrado");
    }

    this.setState((prev) => ({
      ...prev,
      devicesData: null,
      filtersButtons: filteredObjectButtons,
      selectfilter: selectedButton,
      products: null,
      featuredContent: null,
      events: null,
      originalObjectProducts: filteredObjectProducts,
      originalObjectFeaturedContent: filteredObjectCards,
      originalObjectEvents: filteredObjectModules,
      currentCategory: filters || "todos",
      rehydrate: true,
    }));
    console.log("rehydrate");
  }

  componentDidMount() {
    this.setState(
      {
        products: this.state.originalObjectProducts,
        featuredContent: this.state.originalObjectFeaturedContent,
        events: this.state.originalObjectEvents,
      },
      () => {
        // Luego de actualizar el estado, aplico los filtros
        this.applyFiltersFromURL();
      }
    );

    const filteredObjectProducts = this.getMatchingKeysObject(
      this.props.MODULO_FILTER_CATEGORY?.["Pack-Cards"],
      "Product-"
    );

    const filteredObjectCards = this.getMatchingKeysObject(
      this.props.MODULO_CARDS_CONTENIDOS_FILTER,
      "Card-"
    );

    const filteredObjectModules = this.getMatchingKeysObject(
      this.props.MODULO_IMAGENES_CATEGORIA,
      "Module-"
    );

    this.setState({
      products: filteredObjectProducts,
      featuredContent: filteredObjectCards,
      events: filteredObjectModules,
    });
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      typeof window !== "undefined" &&
      `${process.env.NEXT_PUBLIC_ENV_NAME}` === "qa" &&
      this.props.rehydrate &&
      !this.state.rehydrate
    ) {
      this.handleRehydrate();
    }
    // Verificar si la URL ha cambiado desde la última actualización
    if (prevProps.router.asPath !== this.props.router.asPath) {
      // Aplicar filtros cuando la URL cambia
      this.applyFiltersFromURL();
    }
  }

  render() {
    const { props } = this;
    const { productName, alias } = props;
    const {
      devicesData,
      products,
      featuredContent,
      events,
      currentCategory,
      originalAllEvents,
      filtersButtons,
      simpleCard,
      originalSimpleCards,
    } = this.state;

    const showButton = filtersButtons
      ? Object.values(filtersButtons).find((button) => button.selected)
          ?.showButton
      : true;

    return (
      <>
        {props.MODULO_BUYBOX && props.MODULO_CARACTERISTIC_CARDS && (
          <ProductBanner
            productName={productName}
            orionTitle={props.MODULO_H1}
            orionBanner={props.MODULO_BANNER}
            orionBuyBox={props.MODULO_BUYBOX}
            orionSelector={props.MODULO_SELECTOR}
            orionCharacs={props.MODULO_CARACTERISTIC_CARDS}
            orionImageSlider={props.MODULE_SECTION_IMAGE_SLIDER}
            simphePhoneFicha={props.MODULO_SIMPLEPHONE_FICHA}
            alertInfo={props.MODULO_ACTIVACION}
            alias={alias}
          />
        )}

        {(alias === "dazn" || alias === "ficcion-con-disney" || alias === "ficcion-con-netflix") && props && props.MODULO_CATEGORY_PACK_TV && (
          <ChannelList
            title={props.MODULO_CATEGORY_PACK_TV["Text-1"]}
            channelList={props.MODULO_CATEGORY_PACK_TV["Channels-1"]}
            separator
          />
        )}

        {devicesData && <Devices {...devicesData} />}

        {/* Ancla paquetes tv */}
        {props.MODULO_SELECTORES_TV && (
          <>
            <div id="paquetes-tv" style={{ paddingTop: 40 }} />
            <div className="container">
              <TVFilters
                {...{ ...props.MODULO_SELECTORES_TV, alias: props.alias }}
                showButton={showButton}
                applyFilter={this.applyFilterByCategory}
              />
            </div>
          </>
        )}

        {props.MODULO_FILTER_CATEGORY && (
          <TVCard
            cards={products}
            {...props.MODULO_FILTER_CATEGORY}
            category={currentCategory}
            channelCards={originalSimpleCards}
          />
        )}

        {(alias !== "dazn" && alias !== "ficcion-con-disney" && alias !== "ficcion-con-netflix") && props && props.MODULO_CATEGORY_PACK_TV && (
          <ChannelList
            title={props.MODULO_CATEGORY_PACK_TV["Text-1"]}
            channelList={props.MODULO_CATEGORY_PACK_TV["Channels-1"]}
            separator
          />
        )}

        {alias === "futbol-r" && props.MODULO_IMAGENES_CATEGORIA && (
          <>
            {/* Ancla contenido-destacado */}
            <div
              id="contenido-destacado"
              style={{ paddingTop: 25, marginTop: "-25px" }}
            />
            <hr
              className="separator"
              style={{
                color: "#dddddd",
                marginTop: "2rem",
              }}
            />
            <ImageClusterWithoutText
              cards={events}
              category={currentCategory}
              allEvents={originalAllEvents["All-Events-1"]}
              {...props.MODULO_IMAGENES_CATEGORIA}
            />
          </>
        )}

        {props.MODULO_RECOMENDACION && (
          <RecomCharacteristics
            notSeparator={alias === "futbol-r"}
            {...props.MODULO_RECOMENDACION}
          />
        )}

        {/* Ancla contenido-destacado */}
        {alias !== "futbol-r" && props.MODULO_IMAGENES_CATEGORIA && (
          <div
            id="contenido-destacado"
            style={{ paddingTop: 25, marginTop: "-25px" }}
          />
        )}
        {props.MODULO_CARDS_CONTENIDOS_FILTER && (
          <RecomCharacteristics
            cards={featuredContent}
            {...props.MODULO_CARDS_CONTENIDOS_FILTER}
          />
        )}

        {alias !== "futbol-r" && props.MODULO_IMAGENES_CATEGORIA && (
          <ImageClusterWithoutText
            cards={events}
            category={currentCategory}
            allEvents={originalAllEvents["All-Events-1"]}
            {...props.MODULO_IMAGENES_CATEGORIA}
          />
        )}

        {props.MODULO_PACK_CARD && (
          <TVCard {...props.MODULO_PACK_CARD} module="MODULO_PACK_CARD" />
        )}
        {props.MODULO_SIMPLE_CARD && (
          <TVCard
            {...props.MODULO_SIMPLE_CARD}
            cards={simpleCard}
            module="MODULO_SIMPLE_CARD"
          />
        )}

        {props && props.MODULE_DESCRIPTION_IMG && (
          <ImageStepsCard {...props.MODULE_DESCRIPTION_IMG} />
        )}

        {props.MODULO_LISTADO_CONTENIDOS_IMAGENES_EXTRA && (
          <ImageClusertInfoModule
            {...props.MODULO_LISTADO_CONTENIDOS_IMAGENES_EXTRA}
          />
        )}
        {props && props.LISTADO_CARACTERISTICAS_ICONO_TITULO_DESCRIPCION && (
          <AdvantagesSection
            {...props.LISTADO_CARACTERISTICAS_ICONO_TITULO_DESCRIPCION}
            columns={5}
          />
        )}
        {alias === "configura-tv-r" && (
          <div className="container">
            <hr
              className="separator"
              style={{
                color: "#dddddd",
                marginTop: "2rem",
              }}
            />
          </div>
        )}
        {props && props.FAQS_PREGUNTAS_FRECUENTES && (
          <FaqsCompo {...props.FAQS_PREGUNTAS_FRECUENTES} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  context: state.main.context,
});

const mapDispatchToProps = () => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
